<template>
  <div class="products" ref="top">
    <v-row>
      <v-breadcrumbs :items="breadcrumbs" class="px-4">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-col>
      <v-alert
        v-model="ui.alert.enable"
        :dismissible="ui.alert.dismissible"
        :icon="ui.alert.icon"
        :type="ui.alert.type"
        class="mb-0"
      >
        {{ ui.alert.message }}
      </v-alert>
    </v-col>
    <template>
      <!-- Add product from product family  -->
      <v-col cols="12" sm="12" class="pt-10">
        <v-card class="rounded-lg overflow-hidden mb-6" :disabled="ui.disabled">
          <v-card-text class="px-0 pb-0">
            <div class="px-5 px-sm-9 pt-6">
              <v-row>
                <v-col cols="12" class="">
                  <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                    <v-icon class="pl-3 pr-3" color="purple">mdi-text-box-plus-outline</v-icon>
                    <span class="black--text text-center mx-auto">
                          {{ $vuetify.lang.t('$vuetify.sites.products.list.ui.content.families.name') }}
                        </span>
                    <v-tooltip left max-width="30vw">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="pl-3 pr-3 text-right float-right" color="red darken-4" v-bind="attrs"
                                v-on="on">mdi-account-question-outline
                        </v-icon>
                      </template>
                      <span> {{
                          $vuetify.lang.t('$vuetify.sites.products.list.ui.content.families.hint')
                             }}</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    @change="selectFamily"
                    clearable
                    deletable-chips
                    multiple
                    dense
                    small-chips
                    v-model="families.filters"
                    :items="families.items"
                    item-value="text"
                    :label="$vuetify.lang.t('$vuetify.sites.products.list.ui.content.families.select.add')"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-row dense class="mt-4 ml-auto mr-auto align-items-center align-content-center pb-8"
                 style="display: flex;width: 95%;">
            <v-col cols="4" xs="12" sm="12" md="3" lg="4" style="display: flex" v-for="family in families.selected"
                   :key="family.id">
              <family-component style="width: 100vw" :family="family" @switch-auto="productsInFamiliesAuto"
                                @open-dialog="openFamilyDialog"></family-component>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- List of products and add product from dialog list     -->
      <v-col cols="12" sm="12" class="pt-0">
        <v-card
          :loading="loading"
          class="rounded-lg overflow-hidden mb-6"
        >
          <v-card-text class="px-0 pb-0">
            <div class="px-5 px-sm-9 pt-6">
              <v-row>
                <v-col cols="12" class="">
                  <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                    <v-icon class="pl-3 pr-3" color="purple">mdi-text-box-plus-outline</v-icon>
                    <span class="black--text text-center mx-auto">
                          {{ $vuetify.lang.t('$vuetify.sites.products.list.ui.content.products.name') }}
                        </span>
                    <v-tooltip left max-width="30vw">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="pl-3 pr-3 text-right float-right" color="red darken-4" v-bind="attrs"
                                v-on="on">mdi-account-question-outline
                        </v-icon>
                      </template>
                      <span>                          {{
                          $vuetify.lang.t('$vuetify.sites.products.list.ui.content.products.hint')
                                                      }}
</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" sm="12">
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-row class="pa-12 pt-0">
            <v-col cols="12">
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-row>
                <v-col cols="12" class="mb-12">
                  <v-hover v-slot="{ hover }">
                    <v-card class="mr-3 hover-effect" :elevation="hover ? 12 : 2">
                      <v-card-text class="text-center overline justify-center">
                        <div @click="displayDialog">
                          <img class="mt-4" src="../../../assets/box_add.png" style="height: 70px">
                        </div>
                        <p>{{
                            $vuetify.lang.t('$vuetify.sites.products.list.ui.content.products.card.add')
                           }}</p>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
                <!-- Search product               -->
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="search"
                    :label="$vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.search')"
                    solo
                    :disabled="loading"
                    hide-details
                    ref="search"
                    append-icon="mdi-magnify"
                    dense></v-text-field>
                </v-col>
              </v-row>
              <!-- Filter products by family           -->
              <v-row>
                <v-col cols="12">
                  <v-expansion-panels
                    v-model="panel"
                    multiple
                    :class="`elevation-0`"
                  >
                    <v-expansion-panel :class="`elevation-0`">
                      <v-expansion-panel-header>Filtrer par famille produit</v-expansion-panel-header>
                      <v-expansion-panel-content :class="`elevation-0`">
                        <template>
                          <v-row>
                            <v-col cols="4" v-for="filter in filters"
                                   :key="filter.id">
                              <v-checkbox
                                dense
                                v-model="filter.selected"
                                :label="filter._path"
                                @click="filterProducts()"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <!--   Select all / unselect all           -->
              <v-row>
                <v-col cols="12" sm="12">
                  <v-btn
                    class="mr-4"
                    @click="all(true)"
                    :disabled="loading"
                  ><span class="hidden-sm-and-down">{{
                      $vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.select_all')
                                                    }}</span>
                    <v-icon right color="green">
                      mdi-checkbox-multiple-marked-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    @click="all(false)"
                    :disabled="loading"
                  ><span class="hidden-sm-and-down">{{
                      $vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.unselect_all')
                                                    }}</span>
                    <v-icon right color="orange">
                      mdi-checkbox-multiple-blank-outline
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <p class="overline">nombre de produits dans le site: {{ firstTotal }}</p>
                </v-col>
              </v-row>

              <!-- Area for displaying products /display none            -->
              <v-col v-if="!loading && _.isEmpty(site.displayed_products)" class="align-self-center"
                     style="margin-top: 40px">
                <v-img
                  height="300"
                  contain
                  src="@/assets/no_results_found.png"
                ></v-img>
                <v-card-text class="text-center overline justify-center">
                  <p class="mb-0">
                    {{
                      $vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.no_results')
                    }}
                  </p>
                </v-card-text>
              </v-col>
              <v-row v-else @scroll="handleScroll" ref="container" :class="{'opacity': loading}" align="stretch"
                     style="overflow-y:auto; max-height: 900px; margin-top: 40px">
                <v-col cols="3" sm="12" md="3" xs="12" class="flex-column d-flex"
                       v-for="product in site.displayed_products" :key="product.id">
                  <v-progress-circular
                    class="infiniteLoading"
                    indeterminate
                    color="amber"
                    :size="50"
                    v-if="loading === true"
                  ></v-progress-circular>
                  <product-component style="width: 100vw" :product="product"
                                     :loading_from_parent="loading"
                                     @update-selected="selectProduct"></product-component>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions class="d-xs-block px-3 px-sm-7 mt-4">
            <v-btn
              text
              v-on:click="reset">
              {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              text
              v-on:click="save">
              {{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}
            </v-btn>
          </v-card-actions>
          <v-dialog
            v-model="dialog_add"
            width="80%"
            height="99vh"
            persistent
            @keydown.esc="dialog_add=false"
          >
            <dialog-add-products v-if="in_reset === false && dialog_add === true" :site="site"
                                 :families="families_not_modified"
                                 @transfer="modifyProducts" :on-cancel="()=>{dialog_add = false}"></dialog-add-products>
          </v-dialog>
          <v-dialog
            v-for="family in families.selected"
            v-model="family.dialog"
            :key="family.id"
            width="80%"
            height="99vh"
            persistent
          >
            <dialog-refine-family v-if="in_reset === false" :family="family" :site="site"
                                  @transfer="modifyProducts" :onCancel="closeFamiliesDialog"></dialog-refine-family>
          </v-dialog>
          <v-fab-transition class="z-10">
            <div class="v-btn-right-bottom z-10">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="blue-grey lighten-1"
                    fab
                    small
                    dark
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                    :to="{name:'SiteCatalogueList'}"
                    :disabled="ui.disabled">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.global.back') }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="green lighten-1"
                    small
                    fab
                    dark
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="save"
                    :disabled="ui.disabled">
                    <v-icon>mdi-content-save-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}</span>
              </v-tooltip>
            </div>
          </v-fab-transition>
        </v-card>
      </v-col>
    </template>
  </div>
</template>
<script>

import ProductComponent from '@/components/ProductComponent'
import FamilyComponent from '@/components/FamilyComponent'
import DialogAddProducts from './dialog/AddProducts.vue'
import DialogRefineFamily from './dialog/RefineFamily.vue'

export default {
  components: { ProductComponent, FamilyComponent, DialogAddProducts, DialogRefineFamily },
  data: () => ({
    loading: true,
    per_page: 8,
    // all products displayed
    all_displayed: false,
    // panel for open/close filters in products section
    panel: [],
    // filters for list families in products section
    filters: [],
    site: {
      // this variable is used to stock, to avoid refetching the same site has products again
      has_products: [],
      // this variable is used to stock, to avoid refetching the same site has not products again
      has_not_products: [],
      // site has not products to add in site
      added_products: [],
      // site has products to remove in site
      removed_products: [],
      // products displayed in List after search and filter by families
      displayed_products: []
    },
    families: {
      // list of name families for filter in families section
      items: [],
      // list of families with model of filter in families section
      filters: [],
      // list of families selected or site has families only
      selected: []
    },
    // list of families fetched
    families_not_modified: [],
    search_interval: null,
    search: '',
    dialog_add: false,
    // will unmount all dialogs to avoid products duplication
    in_reset: false,
    total: '',
    firstTotal: '',
    ui: {
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    breadcrumbs: [],
    // THE NEW DATA'S STRUCTURE WHICH WILL REPLACE THE OLD ONE
    new_structure_data: {
      // variable for dev
      dev: {
        products: {
          // This variable will never be changed, but just added.
          // It allows to transfer in any dialogs and to go back during a cancel since it will never be modified.
          stocked: {
            in_site: [],
            not_in_site: []
          },
          // This variable will take care of saving our modifications taking into account what has been modified. (selection and / or is_additive)
          // It will be up to the API to set the inverse value of the initial value of the products sent
          modified: {
            selected: [],
            is_additive: []
          }
        },
        families: {
          // This variable will never be changed, but just added.
          // It allows to transfer in any dialogs and to go back during a cancel since it will never be modified.
          stocked: {
            in_site: [],
            not_in_site: []
          },
          // This variable will take care of saving our modifications taking into account what has been modified. (selection and / or is_additive)
          // It will be up to the API to set the inverse value of the initial value of the families sent
          modified: {
            in_site: [],
            not_in_site: []
          }
        }
      },
      // variable for user interface
      ui: {
        // All of page
        generale: {
          alert: {},
          loading: false,
          // reset will unmount all dialogs when it's true
          reset: false,
          breadcrumbs: []
        },
        // site has products section
        site_has_products: {
          // the list of site has products to display after taking into account the search and filtering
          list: [],
          search_interval: null,
          search: '',
          filters: [],
          panel: [],
          per_page: 2,
          all_displayed: false
        },
        // site has not products section
        site_has_not_products: {
          // the list of site has not products to display after taking into account the search and filtering
          list: [],
          search_interval: null,
          search: '',
          filters: [],
          panel: [],
          per_page: 2,
          all_displayed: false,
          // open/close dialogAddProducts
          dialog: false
        },
        // families section
        families: {
          // list all families taking into account the requested model
          filters: [],
          // list all families
          list: [],
          // list site has families and families selected by filters
          selected: []
        }
      }
    }
  }),
  methods: {
    // THE NEW METHOD'S STRUCTURE WHICH WILL REPLACE THE OLD ONE
    newStructureMethods: () => {
      // EXAMPLE CHILD METHOD CALL:
      //    this.fetch().families()
      //
      //    Here, it will perform the code that is directly in the fetch () method,
      //    then launch the families () method
      //
      // METHODS IN ROOT:
      //    getSite () {},
      //    scroll () {},
      //    save () {},
      //    reset () {},
      return {
        fetch: () => {
          return {
            families: () => {
              // fetch families in this.dev.families.stocked.in_site and this.dev.families.stocked.not_in_site
            },
            siteHasProducts: () => {
              // fetch site has products in this.dev.products.stocked.in_site and this.dev.products.stocked.not_in_site
            }
          }
        },
        generate: () => {
          return {
            dynamicBreadcrumbs: () => {
              // generate breadcrumbs dynamically thanks to getSite() method
            },
            filters: () => {
              return {
                families: () => {
                  // add content for v-autocomplete in families section and this.ui.families.filters variable
                },
                has_products: () => {
                  // add content for v-expansion-panel in site has products section and this.ui.site_has_products.filters variable
                },
                has_not_products: () => {
                  // add content for v-expansion-panel in site has not products section and this.ui.site_has_not_products.filters variable
                }
              }
            }
          }
        },
        verify: () => {
          return {
            productBelongsToOneFamiliesSelected: () => {
              // verify if product belongs to one this.ui.families.selected[]
              return {
                isAdditive: () => {
                  // verify if product belongs to one this.ui.families.selected[i].is_additive
                }
              }
            },
            productInSearch: () => {
              // verify if product is in search (search = this.ui.site_has_products.search or this.ui.site_has_not_products.search
            }
          }
        },
        update: () => {
          return {
            dev: () => {
              return {
                stocked: () => {
                  return {
                    inSite: () => {
                      // add new site has products fetched in List to this.dev.stocked.in_site
                    },
                    notInSite: () => {
                      // add new site has not products fetched in DialogAddProduct to this.dev.stocked.not_in_site
                    },
                    all: () => {
                      // add new site has products and site has not products fetched in DialogRefineFamily to this.dev.stocked.in_site and to this.dev.stocked.not_in_site
                    }
                  }
                },
                modified: () => {
                  return {
                    products: () => {
                      return {
                        selected: () => {
                          // update this.dev.modified.products[i].selected
                        },
                        isAdditive: () => {
                          // update this.dev.modified.products[i].is_additive
                        }
                      }
                    },
                    families: () => {
                      return {
                        selected: () => {
                          // update this.dev.modified.families[i].selected
                        },
                        isAdditive: () => {
                          // update this.dev.modified.families[i].selected
                        }
                      }
                    }
                  }
                }
              }
            },
            ui: () => {
              return {
                generale: () => {
                  return {
                    alert: () => {
                      // update this.ui.generale.alert
                    }
                  }
                },
                siteHasProducts: () => {
                  return {
                    list: () => {
                      // update this.ui.site_has_products.list
                    }
                  }
                },
                siteHasNotProducts: () => {
                  return {
                    list: () => {
                      // update this.ui.site_has_not_products.list
                    }
                  }
                },
                families: () => {
                  return {
                    selected: () => {
                      return {
                        dialog: () => {
                          // update this.ui.families.selected[i].dialog (true/false to display the dialog)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    // display dialogAddProducts
    displayDialog () {
      this.dialog_add = true
    },
    // when scroll of products section in bottom, fetch other products
    handleScroll () {
      if (this.$refs.container.scrollTop + this.$refs.container.clientHeight >= this.$refs.container.scrollHeight && !this.all_displayed) {
        this.fetchProductSite()
      }
    },
    // verify if product whereIn families selected
    productInFamiliesSelected (product, families) {
      if (!this._.isEmpty(families)) {
        for (const key in product.families) {
          if (this._.includes(families, product.families[key].id) === true) {
            return true
          }
        }
        return false
      }
      return true
    },
    // verify if product is in search
    productInSearch (product, search) {
      if (!this._.isEmpty(search)) {
        if (this._.lowerCase(product.label).indexOf(this._.lowerCase(search)) !== -1 || this._.lowerCase(product.reference).indexOf(this._.lowerCase(search)) !== -1) {
          return true
        }
        // search in one family ?
        this._.forEach(product.families, (family) => {
          if (this._.lowerCase(family.value).indexOf(this._.lowerCase(search)) !== -1) {
            return true
          }
        })
        return false
      }
      return true
    },
    // takes into account the search as well as the selected families
    async filterProducts () {
      // reset
      this.site.displayed_products = []
      this.all_displayed = false

      // get families selected
      let familiesSelected = this._.filter(this.filters, (family) => {
        if (family.selected === true) return family
      })
      familiesSelected = this._.map(familiesSelected, (family) => {
        return family.id
      })

      // for each site has not products
      this._.forEach(this.site.has_not_products, (product) => {
        const show = {
          hasFamily: false,
          inSearch: false,
          isDisplayed: false
        }

        // check if product in one family selected
        show.hasFamily = this.productInFamiliesSelected(product, familiesSelected)

        // check if product is in search
        show.inSearch = this.productInSearch(product, this.search)

        // check if product is displayed in List
        show.isDisplayed = product.displayed

        // check if all true
        if (show.hasFamily === true && show.inSearch === true && show.isDisplayed === true) {
          this.site.displayed_products.push(product)
        }
      })

      // for each site has products
      this._.forEach(this.site.has_products, (product) => {
        const show = {
          hasFamily: false,
          inSearch: false,
          isDisplayed: false
        }

        // check if product in one family selected
        show.hasFamily = this.productInFamiliesSelected(product, familiesSelected)

        // check if product is in search
        show.inSearch = this.productInSearch(product, this.search)

        // check if product is displayed in List
        show.isDisplayed = product.displayed

        // check if all true
        if (show.hasFamily === true && show.inSearch === true && show.isDisplayed === true) {
          this.site.displayed_products.push(product)
        }
      })

      this.site.displayed_products = this.getUniqueListBy(this.site.displayed_products, 'id')

      /* if (this._.isEmpty(this.site.displayed_products)) {
        await this.fetchProductSite()
      } */

      this.productsInFamiliesAuto()
      return this.site.displayed_products
    },
    async fetchFamilies () {
      this.panel = []
      this.filters = []
      try {
        this.loading = true
        await this.axios.get('/site/' + this.$route.params.id + '/product/families', {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        }).then(({ data }) => {
          this.families_not_modified = data
        }).finally(() => {
          this.loading = false
          return this.families_not_modified
        })
        this.generateExpansionPanel()
        this.generateAutocomplete()
      } catch (e) {
        this.Sentry.captureException(e)
        this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.sites.products.list.ui.notification.load'))
        this.loading = false
      }
    },
    generateExpansionPanel () {
      this.filters = this._.map(this.families_not_modified, (family) => {
        return {
          id: family.id,
          _path: family._path,
          selected: false
        }
      })
    },
    generateAutocomplete () {
      this.families.filters = []
      this.families.items = []
      this.families.selected = []

      this.families.items = this._.map(this.families_not_modified, (family) => {
        return {
          id: family.id,
          text: family._path,
          value: family.value,
          is_additive: family.is_additive,
          nb_products: family.nb_products
        }
      })
      this.families.selected = this._.filter(this.families.items, (family) => {
        family.dialog = false
        if (family.is_additive !== null) {
          return family
        }
      })
      this.families.filters = this._.map(this.families.selected, (family) => {
        return family.text
      })
    },
    openFamilyDialog (data) {
      this.families.selected = this._.filter(this.families.selected, (family) => {
        family.dialog = family.id === data.id
        return family
      })
    },
    closeFamiliesDialog () {
      this.families.selected = this._.filter(this.families.selected, (family) => {
        family.dialog = false
        return family
      })
    },
    // select all if true OR unselect all if false
    async all (select = true) {
      await this.fetchProductSite(true)
      for (const keyDisplayedProduct in this.site.displayed_products) {
        const displayedProduct = this.site.displayed_products[keyDisplayedProduct]
        if (!displayedProduct.is_additive) {
          displayedProduct.selected = select === true
        }
        this.has_products = this._.filter(this.site.has_products, (product) => {
          if (this._.includes(this._.map(this.site.displayed_products, (e) => {
            return e.id
          }), product.id)) {
            if (!product.is_additive) {
              product.selected = select === true
            }
          }
          return product
        })

        for (const keyHasProduct in this.site.has_products) {
          const hasProduct = this.site.has_products[keyHasProduct]
          if (hasProduct.id === displayedProduct.id) {
            if (hasProduct.selected === true) {
              this._.remove(this.site.removed_products, (id) => {
                return id === hasProduct.id
              })
            } else {
              this.site.removed_products.push(hasProduct.id)
            }
          }
        }

        for (const keyHasNotProduct in this.site.has_not_products) {
          const hasNotProduct = this.site.has_not_products[keyHasNotProduct]
          if (hasNotProduct.id === displayedProduct.id) {
            if (hasNotProduct.selected === true) {
              this.site.added_products.push(hasNotProduct.id)
            } else {
              this.site.added_products = this._.remove(this.site.added_products, (id) => {
                return id === hasNotProduct.id
              })
            }
          }
        }
      }
    },
    async fetchProductSite (all = false) {
      if (this.all_displayed === false) {
        try {
          this.loading = true

          // array which get only ids of products already fetch
          const idsProducts = this._.map(this.site.has_products, (product) => {
            return product.id
          })

          // array which get only ids of families selected
          let idsFilters = this._.filter(this.filters, (family) => {
            if (family.selected === true) {
              return family
            }
          })
          idsFilters = this._.map(idsFilters, (family) => {
            return family.id
          })

          await this.axios.post('/site/' + this.$route.params.id + '/product/list', {
            per_page: this.per_page,
            all: all,
            products: idsProducts,
            filters: idsFilters,
            search: this.search

          }, {
            headers: {
              token: this.$session.get('auth_credentials').token
            }
          }).then(({ data }) => {
            this._.forEach(data.data, (product) => {
              product.selected = true
              product.disabled = product.is_additive
              product.displayed = true
              this.site.has_products.push(product)
              this.site.displayed_products.push(product)
            })

            this.site.displayed_products = this.getUniqueListBy(this.site.displayed_products, 'id')

            this.productsInFamiliesAuto()

            if (all === true) {
              this.all_displayed = true
            } else {
              this.total = data.count
              // if count data is equal to size products showed -> all displayed
              if (data.count <= this._.size(
                this._.filter(this.site.has_products, (product) => {
                  if (product.show === true) {
                    return product
                  }
                })
              )) {
                this.all_displayed = true
              }

              if (this.firstTotal === this._.size(this.site.has_products)) {
                this.all_displayed = true
              }
            }
          }).finally(() => {
            this.loading = false
            return this.site.displayed_products
          })
        } catch (e) {
          this.Sentry.captureException(e)
          this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.sites.products.list.ui.notification.load'))
          this.loading = false
        }
      }
    },
    async getSite (id) {
      try {
        const response = await this.axios.get('/site/one/' + id, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })

        return response.data
      } catch (e) {
        this.Sentry.captureException(e)
        this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.sites.products.list.ui.site.notification.load'))
      }
    },
    async dynamicBreadcrumbs () {
      this.site.data = await this.getSite(this.$route.params.id)
      this.breadcrumbs = [
        {
          text: this.$vuetify.lang.t('$vuetify.home.name'),
          disabled: false,
          to: { name: 'Home' }
        },
        {
          text: this.$vuetify.lang.t('$vuetify.sites.name'),
          disabled: true,
          to: { name: 'Home' }
        },
        {
          text: this.site.data.value,
          disabled: true,
          to: { name: 'Home' }
        },
        {
          text: this.$vuetify.lang.t('$vuetify.sites.products.name'),
          disabled: true,
          to: { name: 'SiteCatalogueList', params: { id: this.site.data.id } }
        }
      ]
    },
    selectProduct (data) {
      this.site.has_products = this._.map(this.site.has_products, (product) => {
        if (product.id === data.id) {
          product.selected = data.add === true
        }
        return product
      })
      this.site.displayed_products = this._.map(this.site.displayed_products, (product) => {
        if (product.id === data.id) {
          product.selected = data.add === true
        }
        return product
      })

      if (data.add === true) {
        this.site.removed_products = this._.remove(this.site.removed_products, (product) => {
          return product !== data.id
        })
      } else {
        this.site.removed_products.push(data.id)
      }
    },
    // modify by dialogs
    async modifyProducts (data) {
      if (data.site) {
        this.site = data.site
      }
      if (data.site_has_product) {
        this.site.has_products.push(data.site_has_product)
      } // not need
      if (data.site_has_not_product) {
        this.site.has_not_products.push(data.site_has_not_product)
      } // not need

      if (data.site_has_not_products) {
        this._.each(data.site_has_not_products, (product) => {
          this.site.has_not_products.push(product)
        })
      }
      await this.filterProducts()
    },
    productsInFamiliesAuto () {
      this.site.has_products = this._.filter(this.site.has_products, (product) => {
        product.disabled = false
        return product
      })
      this.site.has_not_products = this._.filter(this.site.has_not_products, (product) => {
        product.disabled = false
        return product
      })
      this.site.displayed_products = this._.filter(this.site.displayed_products, (product) => {
        product.disabled = false
        return product
      })
      this._.forEach(this.families.selected, (familySelected) => {
        this.site.has_products = this._.filter(this.site.has_products, (product) => {
          this._.forEach(product.families, (family) => {
            if (family.id === familySelected.id && familySelected.is_additive === true) {
              product.disabled = true
              product.selected = true
            }
          })
          return product
        })
        this.site.has_not_products = this._.filter(this.site.has_not_products, (product) => {
          this._.forEach(product.families, (family) => {
            if (family.id === familySelected.id && familySelected.is_additive === true) {
              product.disabled = true
              product.selected = true
            }
          })
          return product
        })
        this.site.displayed_products = this._.filter(this.site.displayed_products, (product) => {
          this._.forEach(product.families, (family) => {
            if (family.id === familySelected.id && familySelected.is_additive === true) {
              product.disabled = true
              product.selected = true
            }
          })
          return product
        })
      })
    },
    selectFamily (data) {
      this.families.selected = this._.filter(this.families.items, (family) => {
        if (this._.includes(data, family.text)) {
          return family
        }
      })
      this.families.selected = this._.map(this.families.selected, (family) => {
        family.dialog = false
        return family
      })
      this.productsInFamiliesAuto()
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    getUniqueListBy (arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    },
    async save () {
      try {
        this.loading = true
        await this.axios.post('/site/' + this.$route.params.id + '/product/edit', {
          add: this.site.added_products,
          remove: this.site.removed_products,
          families: this.families.selected
        }, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        }).finally((data) => {
          this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.sites.products.list.notification.save'))
        })

        await this.reset()
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 404:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.sites.products.list.notification.fail'))
              break
            // site not found
            case 400:
              await this.$router.push({
                name: 'Home',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.sites.catalogue.add.notification.site_not_found')
                  }
                }
              })
              break
            // product family not valid
            case 405:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.sites.products.list.notification.product_family_not_found'))
              break
            // product not valid
            case 406:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.sites.products.list.notification.product_not_found'))
              break
            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.sites.products.list.notification.unknown_save'))
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.scrollToTop()
        this.loading = false
      }
    },
    async reset () {
      this.loading = true
      this.search = ''
      this.in_reset = true
      this.site.removed_products = []
      this.site.added_products = []
      this.site.has_not_products = []
      this.site.has_products = []
      this.site.displayed_products = []
      this.families.selected = []
      this.families.filters = []
      this.families_not_modified = []
      this.dialog_add = false
      this.all_displayed = false
      this.panel = []
      await this.fetchFamilies()
        .then(async () => await this.fetchProductSite())
        // .then(async () => await this.filterProducts())
        .finally(() => {
          this.firstTotal = this.total
          this.in_reset = false
        })
    }
  },
  async mounted () {
    await this.fetchFamilies().then(async () => await this.fetchProductSite())
    this.firstTotal = this.total
  },
  watch: {
    async $route () {
      await this.reset().then(async () => await this.dynamicBreadcrumbs())
    },
    search: function (data) {
      if (!this._.isNull(this.search_interval)) clearTimeout(this.search_interval)

      this.search_interval = setTimeout(async () => {
        this.search_interval = null
        await this.filterProducts()
        this.$refs.search.focus()
      }, 500)
    }
  },
  async created () {
    await this.dynamicBreadcrumbs()
  }
}
</script>

<style scoped>

.infiniteLoading {
  text-align: center;
  position: absolute;
  z-index: 9;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.v-input--selection-controls {
  margin-top: 0;
}

.opacity {
  opacity: 0.6
}

</style>
