<template>
  <v-card height="fit-content">
    <v-card-text class="mt-8">
      <!-- Search and close dialog      -->
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <v-icon @click="close">mdi-close</v-icon>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            :label="$vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.search')"
            solo
            ref="search"
            v-model="search"
            :disabled="loading"
            hide-details
            append-icon="mdi-magnify"
            dense></v-text-field>
        </v-col>
      </v-row>
      <!--  Filter products  by family   -->
      <v-row>
        <v-col cols="12">
          <v-expansion-panels
            v-model="panel"
            multiple
            :disabled="loading"
            :class="`elevation-0`"
          >
            <v-expansion-panel :class="`elevation-0`">
              <v-expansion-panel-header>Filtrage</v-expansion-panel-header>
              <v-expansion-panel-content :class="`elevation-0`">
                <template>
                  <v-row>
                    <v-col cols="4" v-for="filter in filters"
                           :key="filter.id">
                      <v-checkbox
                        v-model="filter.selected"
                        :label="filter._path"
                        @click="filterProducts()"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <!-- Select/unselect all          -->
          <v-row>
            <v-col cols="12" class="py-2">
              <v-btn
                class="mr-4"
                @click="all(true)"
                :disabled="loading"
              ><span class="hidden-sm-and-down">{{
                  $vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.select_all')
                                                }}</span>
                <v-icon right color="green">
                  mdi-checkbox-multiple-marked-outline
                </v-icon>
              </v-btn>
              <v-btn
                @click="all(false)"
                :disabled="loading"
              ><span class="hidden-sm-and-down">{{
                  $vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.unselect_all')
                                                }}</span>
                <v-icon right color="orange">
                  mdi-checkbox-multiple-blank-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!--  Alerts        -->
          <v-row>
            <v-col cols="12">
              <v-alert
                v-model="ui.alert.enable"
                :dismissible="ui.alert.dismissible"
                :icon="ui.alert.icon"
                :type="ui.alert.type"
                class="mb-3"
              >
                {{ ui.alert.message }}
              </v-alert>
            </v-col>
          </v-row>
          <!-- Display products/display none zone         -->
          <v-row @scroll="handleScroll" :class="{'hide-overflow': loading}" class="overflow-auto" ref="container"
                 style="max-height: 50vh; ">
            <v-progress-circular class="infiniteLoading"
                                 v-if="loading"
                                 :size="50"
                                 color="amber"
                                 indeterminate
            ></v-progress-circular>
            <!-- If no products found  -->
            <v-col v-else-if="_.isEmpty(displayed_products)" class="align-self-center">
              <v-img
                height="300"
                contain
                src="@/assets/no_results_found.png"
              ></v-img>
              <v-card-text class="text-center overline justify-center">
                <p class="mb-0">
                  {{
                    $vuetify.lang.t('$vuetify.sites.catalogue.add.ui.ui.form.content.families.family_products.no_results')
                  }}
                </p>
              </v-card-text>
            </v-col>
            <!-- Display products found           -->
            <v-col cols="3" style="display: flex" v-for="product in displayed_products" :key="product.id"
                   :class="{'opacity': loading}">
              <product-component style="width: 100vw" :product="product" :loading_from_parent="loading"
                                 @update-selected="selectProduct"></product-component>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="cancel"
      >
        {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="save"
      >
        {{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import ProductComponent from '@/components/ProductComponent'

export default {
  components: { ProductComponent },
  props: {
    site: Object,
    families: Array,
    onCancel: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    loading: false,
    per_page: 8,
    // all products displayed
    all_displayed: false,
    displayed_products: [],
    panel: [],
    filters: [],
    siteMutating: {},
    search_interval: null,
    search: '',
    total: '',
    firstTotal: '',
    ui: {
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    breadcrumbs: []
  }),
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    close () {
      this.search = ''
      this.ui.alert.enable = false
      this.onCancel()
    },
    async handleScroll () {
      if (this.$refs.container.scrollTop + this.$refs.container.clientHeight >= this.$refs.container.scrollHeight && !this.all_displayed) {
        await this.fetchProduct()
      }
    },
    productInFamiliesSelected (product, families) {
      if (!this._.isEmpty(families)) {
        for (const key in product.families) {
          if (this._.includes(families, product.families[key].id) === true) {
            return true
          }
        }
        return false
      }
      return true
    },
    productInSearch (product, search) {
      if (!this._.isEmpty(search)) {
        if (this._.lowerCase(product.label).indexOf(this._.lowerCase(search)) !== -1 || this._.lowerCase(product.reference).indexOf(this._.lowerCase(search)) !== -1) {
          return true
        }
        // search in one family ?
        this._.forEach(product.families, (family) => {
          if (this._.lowerCase(family.value).indexOf(this._.lowerCase(search)) !== -1) {
            return true
          }
        })
        return false
      }
      return true
    },
    filterProducts () {
      // reset
      this.displayed_products = []
      // this.all_displayed = false

      // get families selected
      let familiesSelected = this._.filter(this.filters, (family) => {
        if (family.selected === true) return family
      })
      familiesSelected = this._.map(familiesSelected, (family) => {
        return family.id
      })

      // for each site has not products
      this._.forEach(this.siteMutating.has_not_products, (product) => {
        const show = {
          hasFamily: false,
          inSearch: false,
          isDisplayed: false
        }

        // check if product in one family selected
        show.hasFamily = this.productInFamiliesSelected(product, familiesSelected)

        // check if product is in search
        show.inSearch = this.productInSearch(product, this.search)

        // check if product is displayed in List
        show.isDisplayed = product.displayed

        // check if all true
        if (show.hasFamily === true && show.inSearch === true && show.isDisplayed === false) {
          this.displayed_products.push(product)
        }
      })
      /* if (this._.isEmpty(this.displayed_products)) {
        await this.fetchProduct()
      } */
      this.loading = false
      return this.displayed_products
    },
    generateExpansionPanel () {
      this.filters = this._.map(this.families, (family) => {
        return {
          id: family.id,
          _path: family._path,
          selected: false
        }
      })
    },
    // select all if true OR unselect all if false
    async all (select = true) {
      await this.fetchProduct(true)
      for (const keyDisplayedProduct in this.displayed_products) {
        const displayedProduct = this.displayed_products[keyDisplayedProduct]
        if (!displayedProduct.is_additive) {
          displayedProduct.selected = select === true
        }
        this.siteMutating.has_not_products = this._.filter(this.siteMutating.has_not_products, (product) => {
          if (this._.includes(this._.map(this.displayed_products, (e) => {
            return e.id
          }), product.id)) {
            if (!product.is_additive) {
              product.selected = select === true
            }
          }
          return product
        })

        for (const keyHasNotProduct in this.siteMutating.has_not_products) {
          const hasNotProduct = this.siteMutating.has_not_products[keyHasNotProduct]
          if (hasNotProduct.id === displayedProduct.id) {
            if (hasNotProduct.selected === true) {
              this.siteMutating.added_products.push(hasNotProduct.id)
            } else {
              this.siteMutating.added_products = this._.remove(this.siteMutating.added_products, (id) => {
                return id === hasNotProduct.id
              })
            }
          }
        }
      }
    },
    async fetchProduct (all = false) {
      if (this.all_displayed === false) {
        try {
          this.loading = true

          // array which get only ids of products already fetch
          const idsProducts = this._.map(this.siteMutating.has_not_products, (product) => {
            return product.id
          })
          // array which get only ids of families selected
          let idsFilters = this._.filter(this.filters, (family) => {
            if (family.selected === true) {
              return family
            }
          })
          idsFilters = this._.map(idsFilters, (family) => {
            return family.id
          })

          await this.axios.post('/site/' + this.$route.params.id + '/product/list', {
            inverse: true,
            per_page: this.per_page,
            all: all,
            products: idsProducts,
            filters: idsFilters,
            search: this.search
          }, {
            headers: {
              token: this.$session.get('auth_credentials').token
            }
          }).then(({ data }) => {
            this._.forEach(data.data, (product) => {
              product.disabled = false
              product.selected = false
              product.displayed = false
              this.siteMutating.has_not_products.push(product)
              this.displayed_products.push(product)
            })
            this.$emit('transfer', {
              site_has_not_products: data.data
            })
            this.total = data.count
            // if count data is equal to size products showed -> all displayed
            if (data.count === 0 || this.firstTotal === this._.size(this.displayed_products)) {
              this.all_displayed = true
            }
          }).finally(() => {
            this.loading = false
            return this.displayed_products
          })
        } catch (e) {
          this.Sentry.captureException(e)
          this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.sites.products.list.ui.notification.load'))
          this.loading = false
        }
      }
    },
    selectProduct (data) {
      this.siteMutating.has_not_products = this._.map(this.siteMutating.has_not_products, (product) => {
        if (product.id === data.id) {
          product.selected = data.add === true
        }
        return product
      })
      this.displayed_products = this._.map(this.displayed_products, (product) => {
        if (product.id === data.id) {
          product.selected = data.add === true
        }
        return product
      })

      if (data.add === true) {
        this.siteMutating.added_products.push(data.id)
      } else {
        this.siteMutating.added_products = this._.remove(this.siteMutating.added_products, (product) => {
          return product === data.id
        })
      }
    },
    async save () {
      this.siteMutating.has_not_products = this._.filter(this.siteMutating.has_not_products, (product) => {
        if (product.selected === true) {
          product.displayed = true
        }
        return product
      })
      this.$emit('transfer', {
        site: this.siteMutating
      })
      this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.sites.products.list.ui.content.families.family_products.notification.added'))
    },
    async cancel () {
      this.siteMutating = this._.cloneDeep(this.site)
      this.search = ''
      this.generateExpansionPanel()
      await this.filterProducts()
    }
  },
  async mounted () {
    this.loading = true
    this.siteMutating = this._.cloneDeep(this.site)
    this.displayed_products = []
    this.generateExpansionPanel()
    await this.fetchProduct().then(async () => await this.filterProducts())
    this.firstTotal = this.total
  },
  watch: {
    search: function (data) {
      if (!this._.isNull(this.search_interval)) clearTimeout(this.search_interval)

      this.search_interval = setTimeout(async () => {
        this.search_interval = null
        this.loading = true
        await this.filterProducts()
        this.$refs.search.focus()
      }, 500)
    },
    site: {
      deep: true,
      async handler () {
        this.siteMutating = this._.cloneDeep(this.site)
        await this.filterProducts()
      }
    }
  }
}
</script>
<style scoped>

.infiniteLoading {
  text-align: center;
  position: absolute;
  z-index: 9;
  border-radius: 5px;
  left: calc(50% - 45px);
  top: calc(50% - 18px);
}

.v-input--selection-controls {
  margin-top: 0;
}

.hide-overflow {
  overflow: hidden !important;
}

.opacity {
  opacity: 0.6
}

</style>
